@font-face {
  font-family: jost;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./Component/assets/images/4c472ffaefe8ebfa-s.p.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+0304, U+0308, U+0329, U+2000-206f, U+2074, U+20ac, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+feff, U+fffd;
}
body {
  margin: 0;
  padding: 0;
  color: #404a60;
  font-family: jost;
}

/* Global css */
.rel {
  position: relative;
}
.Hero_Bg {
  overflow: hidden;
}
.Hero_Bg:after {

  background-color: #064459de;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  opacity: 1;
}
.Hero_Bg:before,
.Hero_Bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.Hero_Bg .container{
  position: relative;
  z-index: 2;
}

.container {
  padding: 0% 12%;
}
.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}
.col-1,
.col-2 {
  width: 100%;
  text-align: center;
}
.col-2 {
  text-align: center;
}
.WhyChooseUs .btn-group{
  justify-content: center;
}
.col-2 div {
  width: 100%;
  text-align: right;
}
.col-2 div img {
  width: 100%;
  height: 100%;
}
span {
  color: #156a8f;
  margin-bottom: 16px;
  
  align-items: center;
  gap: 16px;
  font-size: 20px;
}
h1 {
  color: white;
  margin-bottom: 24px;
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: inherit;
  margin-top: 5px;
}
p {
  color: #fafafb;
  font-size: 22px;
  margin-bottom: 0px;
  font-weight: 500;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 24px;
  padding-left: 0px;
}
.btn-group a {
  text-decoration: none;
  display: inline-flex;
  gap: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #222e48;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: inherit;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  border-radius: 35px;
  transition: all 0.7s ease-in-out;
  color: #fff;
  border: 1px solid #156a8f;
  background-color: #156a8f !important;
}
.WhyChooseUs {
  padding: 40px 0px;
}
.Services {
  padding: 40px 0px;
}
.Contact h2 {
  color: white;
  text-align: left;
}
.WhyChooseUs:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1;
  background-color: #004a63;
}
.WhyChooseUs .col-2 {
  text-align: left !important;
}
.WhyChooseUs h2 {
  color: white;
}
.WhyChooseUs .card p {
  color: white;
}
.HowWorks .card p {
  color: #156a8f;
}
.card-icon img {
  width: 45px;
}
.HowWorks .card-icon {
  background-color: rgb(241, 238, 238);
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.HowWorks .card h4 {
  color: #004a63 !important;
}
.WhyChooseUs h4 {
  color: white;
}
.WhyChooseUs span {
  font-size: 24px;
  color: white;
  font-weight: 600;
  text-align: left;
}
.HowWorks span {
  color: #156a8f;
  text-align: left;
  display: block;
}
h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
  color: #021b24;
  letter-spacing: inherit;
  margin: 5px;
}
.WhyChooseUs h2{
  text-align: left;
}
.Services .col-1 {
  width: 100%;
}
.Services .card_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  color: #004a63;
  letter-spacing: inherit;
  margin: 0;
}
.WhyChooseUs p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #156a8f;
}
.card {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  gap: 20px;
}
.Hero_Bg br{
  display: none;
}
.card h4 {
  margin: 0;
  margin-bottom: 8px;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: inherit;
}
.card-icon {
  background-color: white;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.HowWorks .card h4 {
  color: #004a63 !important;
}
.WhyChooseUs .card h4 {
  color: white;
}
.Navbar .logo img {
  /* filter: drop-shadow(0 0 0.75rem rgb(187, 183, 159)); */
}
.card p {
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  color: #156a8f;
}
span img {
  width: 40px;
}
.Services .card p {
  color: white;
}
.Services span {
  color: #156a8f;
}
.Services p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #156a8f;
}
.Services .card {
  background: #fff;
  padding: 36px;
  border-radius: 16px;
  /* border: 1px solid #dfe0e4; */
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  flex-direction: column;
}
.Services .card:after {
  content: "";
  position: absolute;
  z-index: 1;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  background: #004a63;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  transition: all 0.35s ease-in-out;
  z-index: -3;
}
.card_content {
  text-align: left;
}
.HowWorks {
  background-color: white !important;
}
.Contact {
  padding-top: 40px;
  width: 100%;
}
.Contact:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  /* width: calc(100% - 320px); */
  background-color: #004a63;
  z-index: -1;
  width: 100%;
}

.Services .card {
  margin: 0px;
}
.Services .row {
  gap: 1rem;
  flex-direction: column;
}
.Hero_Bg span {
  color: white;
}

.Navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 111;
}
.fix {
  padding-top: 150px;
  padding-bottom: 50px;
  position: relative;
}

.Navbar-c {
  padding: 0 12%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.WhyChooseUs .c2 div {
  text-align: left !important;
}
.logo {
  width: 50%;
}
.logo img {
  width: 70%;
}
.btn-group a {
  background-image: linear-gradient(white, white), linear-gradient(#0a1f29, #12a1df);;
  background-size: 0 100%, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-out;
}
.btn-group a:hover {
  background-color: white;
  color: #156a8f;
  transition: 0.3s ease;
  background-size: 100% 100%, auto;
}
.Services .card:hover::after {
  background-color: #156a8f;
  cursor: pointer;
  transition: 0.3s ease;
}
.mee .card {
  background-color: #156a8f;
  padding: 12px;
  border-radius: 16px;
}
.mee .card:hover {
  background-color: white;
  transition: 0.3s ease;
}
.mee .card:hover h4 {
  color: #004a63;
}
.mee .card:hover p {
  color: #004a63;
}
.mee .card:hover .card-icon {
  background-color: #004a63;
}
/* src/styles/animations.css */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-from-left {
  opacity: 0;
}

.animate-from-right {
  opacity: 0;
}

.animate {
  opacity: 1 !important;
}

.animate-from-left.animate {
  animation: slideInFromLeft 1s forwards;
}

.animate-from-right.animate {
  animation: slideInFromRight 1s forwards;
}
.animate-from-top.animate {
  animation: slideInFromTop 1s forwards;
}

.animate-from-bottom.animate {
  animation: slideInFromBottom 1s forwards;
}
.MobileNavbar {
  display: none;
}
.Footer {
  padding: 5px 12%;
  /* background-color: #d5d9d8; */
  padding-top: 25px;
  background-color: white;
}
.HowWorks a {
  text-decoration: none;
  color: white;
}
.HowWorks p a {
  color: #156a8f;
}
.HowWorks a:hover {
  color: #156a8f;
}
.Footer1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Footer1 img {
  margin-top: 40px;
  width: 70%;
}
.Footer a {
  text-decoration: none;
  color: #404a60;
  font-size: 16px;
}
.Footer li {
  font-size: 18px;
}
.Footer span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-size: 18px;
}
.Footer ul {
  padding: 0;
  margin: 0;
}
.Footer ul li {
  list-style-type: none;
  cursor: pointer;
}
.pages-content {
  padding: 12% 12%;
}



.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: contain;
  transform: translate(-50%, -50%);
  width: 100%;
}
.circle {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small {
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1 {
  opacity: 0.2;
}
.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}
.lp2222 {
  background: -moz-linear-gradient(
    45deg,
    #004a63 0%,
    #004a63 29%,
    #004a63 66%,
    #dbdad9 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #004a63 0%,
    #004a63 29%,
    #004a63 66%,
    #dbdad9 100%
  );
  background: linear-gradient(
    45deg,
    #004a63 0%,
    #004a63 29%,
    #004a63 66%,
    #dbdad9 100%
  );
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  /*min-height: calc(100vh - 2rem);*/
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  overflow: hidden;
  position: relative;
}

.lp2222::before,
.lp2222::after {
  content: "";
  width: 70vmax;
  height: 70vmax;
  position: absolute;
  background: rgba(255, 255, 255, 0.07);
  left: -20vmin;
  top: -20vmin;
  animation: morph 15s linear infinite alternate, spin 20s linear infinite;
  z-index: 1;
  will-change: border-radius, transform;
  transform-origin: 55% 55%;
  pointer-events: none;
}

.lp2222::after {
  width: 70vmin;
  height: 70vmin;
  left: auto;
  right: -10vmin;
  top: auto;
  bottom: 0;
  animation: morph 10s linear infinite alternate,
    spin 26s linear infinite reverse;
  transform-origin: 20% 20%;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
  }
  100% {
    border-radius: 40% 60%;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
.st0 {
  display: none;
}
.st1 {
  display: inline;
}
.st2 {
  opacity: 0.29;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  clip-path: url(#SVGID_2_);
  fill: #ffffff;
}
.st5 {
  clip-path: url(#SVGID_4_);
}
.st6 {
  clip-path: url(#SVGID_6_);
}
.st7 {
  clip-path: url(#SVGID_8_);
}
.st8 {
  clip-path: url(#SVGID_10_);
}
.st9 {
  fill: none;
}
.st10 {
  clip-path: url(#SVGID_12_);
}
.st11 {
  opacity: 0.7;
}
.st12 {
  clip-path: url(#SVGID_14_);
}
.st13 {
  opacity: 0.2;
}
.st14 {
  clip-path: url(#SVGID_16_);
}
.st15 {
  opacity: 0.3;
  fill: #ffffff;
  enable-background: new;
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
img{
  border-radius: 12px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.out-top {
  animation: rotate 20s linear infinite;
  transform-origin: 13px 25px;
}
.in-top {
  animation: rotate 10s linear infinite;
  transform-origin: 13px 25px;
}
.out-bottom {
  animation: rotate 25s linear infinite;
  transform-origin: 84px 93px;
}
.in-bottom {
  animation: rotate 15s linear infinite;
  transform-origin: 84px 93px;
}
.svg {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.elementor-widget-container {
  color: #156a8f;
  font-size: 20px;
  padding: 0% 8%;
}
.elementor-widget-container p{
  line-height: 22px;
}
.elementor  section:first-of-type {
  background-color: #177580;
  padding: 10% 0%;
  color: white;
  padding-bottom: 5%;
  margin-bottom: 30px;
}
.elementor  section:first-of-type p{
color: white;
}
.elementor  section:first-of-type h2{
  color: white;
}
.elementor  section:first-of-type div b{
  color: white;
}
.elementor  section:first-of-type p a strong{
  color: white!important;
}
.elementor  section:first-of-type p  strong{
  color: white!important;
  line-height: 26px;
}
.elementor  section:first-of-type a{
  color: white!important;
}
a{
  text-decoration: none;
  color: #156a8f;
}
section p{
  color: #156a8f; 
}
.elementor p strong{
  color: #156a8f;

}
section ul li{
  font-size: 20px;
  color: #156a8f;
}
.elementor div b{
  color: #177580;

}
.elementor h4{
  color: #177580;
  font-size: 22px;
  margin: 10px;
  margin-left: 0px;
}

.elementor li strong{
  color: #177580;
}
.elementor p a{
  text-decoration: none;
  color: #177580;

}
@media (max-width: 990px) {
  .row {
    flex-direction: column;
  }
  .col-1 {
    width: 100%;
  }
  .col-2 {
    width: 70%;
    text-align: center;
  }
  .col-2 div {
    text-align: center !important;
  }
  .WhyChooseUs .c2 div {
    text-align: center !important;
  }
  .WhyChooseUs .row {
    flex-direction: column-reverse;
  }
  .HowWorks {
    flex-direction: column;
  }
  .btn-group {
    display: flex;
    justify-content: center;
  }
  .btn-group {
    padding: 30px;
  }
}
@media (max-width: 768px) {
  .Navbar {
    display: none;
  }
  .Footer1 {
    flex-direction: column;
    padding-bottom: 10px;
  }
  Footer {
    padding: 40px 7% !important;
    background-color: #d5d9d8;
  }
  .mobile {
    display: block;
  }
  .mobile img {
    width: 100%;
  }
  .desktop {
    display: none;
  }
  .Footer span {
    margin-bottom: 70px;
    font-size: 15px;
  }
  .bottomFixed {
    background-color: #156a8f;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1111;
    padding: 10px 0px;
  }
  .topFixed {
    text-align: center;
    padding: 10px;
  }
  /* Root variables for easy customization */
:root {
  --btn-border: 1px solid rgba(255, 255, 255, 0.2);
  --btn-bg: #004a63; /* Background color for the button */
  --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 1);
  --btn-text-color: #f4f4f4;
  --shine-color: #79aabe; /* Prominent shine */
  --shine-degree: -45deg;
  --shine-duration: 6s; /* Duration of the shine animation */
}

/* Styling for the button */
.bottomFixed a {
  text-decoration: none;
  color: var(--btn-text-color);
  font-size: 22px;
  font-weight: 600;
  background-color: var(--btn-bg);
  width: 90%;
  display: block;
  margin: 0 auto;
  height: 90%;
  padding: 8px 0;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border: var(--btn-border);
  letter-spacing: 1px;
}

/* Shining animation */
.bottomFixed a::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%; /* Start off screen */
  width: 100%;
  height: 100%;
  background: linear-gradient(
    var(--shine-degree),
    transparent 5%, /* Start the transparent part */
    var(--shine-color) 10%, /* Narrower white shine */
    transparent 15% /* End the transparent part */
  );
  animation: shine var(--shine-duration) linear infinite;
}
.lp111{
  position: relative;
  overflow: hidden;
}
/* Keyframes for the shine animation */
@keyframes shine {
  0% {
    left: -150%; /* Start off screen */
  }
  50% {
    left: 150%; /* Move across the button */
  }
  50.01% {
    left: -150%; /* Restart immediately */
  }
  100% {
    left: 150%; /* Move across the button */
  }
}

  .MobileNavbar {
    display: block;
  }
  .col-2 {
    width: 100%;
  }
  span {
    font-size: 22px;
  }
  .Hero_Bg h1 {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
  .btn-group a {
    padding: 12px 4px;
    font-size: 20px;
  }
  .WhyChooseUs span {
    font-size: 22px;
  }
  .btn-group {
    padding: 30px 0px !important;
    width: 100%;
  }
  .btn-group a {
    width: 100%;
  }
  .fix,
  .WhyChooseUs,
  .Services {
    padding: 20px 0px;
  }
  h2 {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 28px;
  }
  .WhyChooseUs p {
    margin-bottom: 0px;
  }
  .card h4 {
    font-size: 25px;
  }
  .card p {
    font-size: 20px;
  }
  .Services .card {
    padding: 32px;
  }
  .mee .card {
    flex-direction: column;
    padding: 20px;
  }
  .HowWorks .card {
    flex-direction: column;
  }
  .row {
    gap: 0rem;
  }
  .Services .card {
    flex-direction: column;
  }
  .Services .card_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .lp111 .Hero_Bg h1{
    color: #156a8f !important;
  }
  .topFixed img{
    width: 100%;
  }
  .Footer1 img {
    margin-top: 10px;
    width: 100%;
}
.Contact h2{
  text-align: center;
}
.Contact .row{
  flex-direction: column-reverse;
}
.Contact{
  padding-top: 10px;
}
.Hero_Bg br{
  display: block;
}
}
